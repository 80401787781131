import _ from 'lodash';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAsyncResult } from 'react-use-async-result';
import { hospitalApi } from '../api';
import { useStore, useStoreDispatch } from '../context';
import { TFunc, useTranslation } from '../i18n';
import { AuthService } from '../services/auth';
import { Input } from './input';

type ValidationRule = {
  validate: (value: any) => boolean,
  errorMessage: string,
};

type Mode = 'add' | 'edit';

interface Field {
  name: keyof FormData;
  rules: ValidationRule[];
  visible: Mode[];
  options?: { value: string, label: string }[];
}

const getFields = (t: TFunc): Field[] => {
  const rules = getValidationDefs(t);
  return [
    { name: 'firstName', rules: [rules.required('first name')], visible: ['add', 'edit'] },
    { name: 'lastName', rules: [rules.required('last name')], visible: ['add', 'edit'] },
    { name: 'email', rules: [rules.required('email'), rules.email()], visible: ['add', 'edit'] },
    { name: 'phone', rules: [rules.phone()], visible: ['edit'] },
    { name: 'bio', rules: [], visible: ['edit'] },
    {
      name: 'language',

      rules: [],
      visible: ['edit'],
      options: [
        { value: 'en', label: t('English') },
        { value: 'fr', label: t('French') },
      ],
    },
    { name: 'clinicId', rules: [rules.required('clinic')], visible: ['add'] },
  ];
};

const getValidationDefs = (t: TFunc) => ({
  required: (fieldName: string): ValidationRule => ({
    validate: (value) => !!value && value.toString().trim() !== '',
    errorMessage: t(`Please enter a ${fieldName}`),
  }),
  email: (): ValidationRule => ({
    validate: (value) => /^[\w.%+-]+@[\w-]+\.+[\w]{2,}$/i.test(value),
    errorMessage: t('Please enter a valid email'),
  }),
  phone: (): ValidationRule => ({
    validate: (value) => !value || value.replace(/\D/g, '').length === 10,
    errorMessage: t('Please enter a valid phone number'),
  }),
});

type FormData = {
  firstName: string,
  lastName: string,
  email: string,
  bio: string,
  phone: string,
  language: string,
  clinicId: number | undefined,
};

export const AddClinician = (props: { mode: Mode, toggle: () => void, show: boolean }) => {
  const { clinician } = useStore();
  const { setClinician } = useStoreDispatch();
  const authService = AuthService();
  const { t } = useTranslation();
  const fields = getFields(t);

  const clinicOptions = clinician.clinics
    .filter(c => c.hospital_id === clinician.hospital_id)
    .sort((a, b) => a.name.localeCompare(b.name));

  const getInitialFormData = (): FormData => ({
    firstName: props.mode === 'add' ? '' : clinician.first_name || '',
    lastName: props.mode === 'add' ? '' : clinician.last_name || '',
    email: props.mode === 'add' ? '' : clinician.email || '',
    bio: props.mode === 'add' ? '' : clinician.bio || '',
    phone: props.mode === 'add' ? '' : clinician.phone_number || '',
    language: props.mode === 'add' ? 'en' : clinician.locale || 'en',
    clinicId: clinicOptions?.length === 1 ? clinicOptions[0].id : undefined,
  });

  const [formData, setFormData] = useState<FormData>(getInitialFormData());
  const [errors, setErrors] = useState<Record<keyof FormData, string>>({} as Record<keyof FormData, string>);
  const [passwordText, setPasswordText] = useState('');

  const resetPassword = async () => {
    const response = await authService.resetPassword(formData.email);
    setPasswordText(t('Sent! Check your email for a password reset link.'));
    if (!response) {
      setPasswordText(t('Reset password failed. Please contact technical support at support@inneranalytics.com'));
    }
  };

  const validateField = (field: Field): boolean => {
    const value = formData[field.name];
    for (const rule of field.rules) {
      if (!rule.validate(value)) {
        setErrors(prev => ({ ...prev, [field.name]: rule.errorMessage }));
        return false;
      }
    }
    setErrors(prev => ({ ...prev, [field.name]: '' }));
    return true;
  };

  const saveRes = useAsyncResult();
  const handleInputChange = <T extends keyof FormData>(field: T) => (e: any) => {
    const value = e.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));
    validateField(fields.find(f => f.name === field)!);
  };

  const handleClinicChange = (e) => {
    if (typeof e[0]?.id === 'number') {
      const clinicId = parseInt(e[0]?.id);
      setFormData(prev => ({ ...prev, clinicId }));
    }
  };

  const reset = () => {
    setFormData(getInitialFormData());
    setErrors({} as Record<keyof FormData, string>);
    setPasswordText('');
  };

  const handleSave = async () => {
    const visibleFields = fields.filter(field => field.visible.includes(props.mode));
    if (!visibleFields.every(field => validateField(field))) {
      return;
    }
    if (props.mode === 'add') {
      saveRes.bind(
        hospitalApi.appApiHospitalPostClinicians({
          hospital_id: clinician.hospital_id,
          CreateClinicianRequest: {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            bio: formData.bio,
            clinic_ids: [formData.clinicId],
          },
        }),
      );
    } else {
      const initialFormData = getInitialFormData();
      if (Object.keys(formData).every(key => formData[key] === initialFormData[key])) {
        props.toggle();
        return;
      }
      saveRes.bind(
        hospitalApi.appApiHospitalPatchClinician({
          hospital_id: clinician.hospital_id,
          clinician_id: clinician.id,
          PatchClinicianRequest: {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            phone_number: formData.phone,
            bio: formData.bio,
            locale: formData.language,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (props.show && saveRes.isDone) {
      if (props.mode === 'edit') {
        setClinician({
          ...clinician,
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          phone_number: formData.phone,
          bio: formData.bio,
          locale: formData.language,
        });
      }
      saveRes.clear();
      reset();
      props.toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveRes.isDone]);

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={props.show} toggle={props.toggle} centered size="lg">
        <MDBModalHeader toggle={props.toggle}>
          {props.mode === 'add' ? t('Add new clinician') : t('Edit my profile')}
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row style={{ height: '90px' }}>
              <Col className="verticalCenter">
                <Input
                  type="text"
                  label={t('First name')}
                  background
                  size="lg"
                  error={errors.firstName}
                  value={formData.firstName}
                  onChange={handleInputChange('firstName')}
                  onBlur={() => validateField(fields.find(f => f.name === 'firstName')!)}
                />
              </Col>
              <Col className="verticalCenter">
                <Input
                  type="text"
                  label={t('Last name')}
                  background
                  size="lg"
                  error={errors.lastName}
                  value={formData.lastName}
                  onChange={handleInputChange('lastName')}
                  onBlur={() => validateField(fields.find(f => f.name === 'lastName')!)}
                />
              </Col>
            </Row>
            {props.mode === 'add' && (
              <>
                <Row style={{ height: '90px' }}>
                  <Col className="verticalCenter">
                    <Input
                      type="email"
                      label={t('Email')}
                      background
                      size="lg"
                      error={errors.email}
                      value={formData.email}
                      onChange={handleInputChange('email')}
                      onBlur={() => validateField(fields.find(f => f.name === 'email')!)}
                    />
                  </Col>
                </Row>
                {clinicOptions.length > 1 && (
                  <Row className="mt-4" style={{ height: '90px' }}>
                    <Col>
                      <Typeahead
                        id="clinicSelect"
                        labelKey="name"
                        options={clinicOptions}
                        onChange={handleClinicChange}
                        placeholder={t('Select a clinic')}
                      />
                      <p className="errorMessage errorLabel mt-1">{errors.clinicId}</p>
                    </Col>
                  </Row>
                )}
                <Row className="mb-5" />
              </>
            )}
            {props.mode === 'edit' && (
              <>
                <Row style={{ height: '100px' }}>
                  <Col className="verticalCenter">
                    <Input
                      type="text"
                      label={t('Phone number')}
                      background
                      size="lg"
                      error={errors.phone}
                      value={formData.phone}
                      onChange={handleInputChange('phone')}
                      onBlur={() => validateField(fields.find(f => f.name === 'phone')!)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="verticalCenter">
                    <label htmlFor="Language">{t('Language')}:</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: '-20px', height: '90px' }}>
                  <Col>
                    <Form.Group className="margin-m">
                      <Form.Control
                        as="select"
                        value={formData.language}
                        onChange={handleInputChange('language')}
                        className="custom-select mb-3"
                      >
                        <option value="en">{t('English')}</option>
                        <option value="fr">{t('French')}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ height: '90px', marginTop: '-20px' }}>
                  <Col>
                    <Input
                      label={t('Biography')}
                      type="text"
                      id="Biography"
                      onChange={handleInputChange('bio')}
                      value={formData.bio}
                    />
                  </Col>
                </Row>
                <Row className="mb-5 mt-4">
                  <Col className="verticalCenter">
                    <button onClick={resetPassword}>
                      {t('Change Password')}
                    </button>
                    <div id="passwordTextMsg" className="verticalCenter mt-1">
                      {passwordText}
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                {saveRes.isError && (
                  <div id="saveErrorMsg" className="mb-2 error">
                    {saveRes.error.message}
                  </div>
                )}
                <Button onClick={handleSave} block disabled={saveRes.isPending}>
                  {props.mode === 'add' ? t('add new Clinician') : t('save changes')}
                </Button>
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
