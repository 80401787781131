import classnames from 'classnames';
import { MDBFormInline, MDBIcon } from 'mdbreact';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { AddClinician } from '../components/addClinician';
import { AddUser, InviteCodeModal } from '../components/addUser';
import { SendLoggingTrial } from '../components/sendLoggingTrial';
import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import logo from '../logo.svg';
import { PatientService } from '../services/patient';
import './patientlist.scss';
import { DateTime } from 'luxon';
import { useAsyncResult } from 'react-use-async-result';
import { NumericArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { create } from 'zustand';
import { hospitalApi } from '../api';
import { PatientTable } from '../components/PatientTable';

const getFlagFilters = (t) => [
  // eslint-disable-next-line i18next/no-literal-string
  ['flagNotSeen1Month', t("hasn't logged meal in a month")],
  // eslint-disable-next-line i18next/no-literal-string
  ['hasReportSoon', t('has a report soon')],
  // eslint-disable-next-line i18next/no-literal-string
  ['flagDraftReportAvailable', t('draft report available')],
];

const getAddedByFilter = (t) => [
  // eslint-disable-next-line i18next/no-literal-string
  ['addedByMe', t('added by me')],
  // eslint-disable-next-line i18next/no-literal-string
  ['addedByOthers', t('added by others')],
];

const getSubscriptionFilters = (t) => [
  // eslint-disable-next-line i18next/no-literal-string
  ['active', t('active')],
  // eslint-disable-next-line i18next/no-literal-string
  ['completed', t('completed')],
  // eslint-disable-next-line i18next/no-literal-string
  ['upcoming', t('upcoming')],
  // eslint-disable-next-line i18next/no-literal-string
  ['expired', t('expired')],
];

const FilterDropdown = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    props.onClick(e);
  };

  return (
    <a href="" onClick={handleClick}>
      {props.children}
    </a>
  );
};

export type patientListFiltersType = {
  names: string,
  flags: string,
  clinics: number[],
  addedBy: string,
  subscriptions: string,
};

export const defaultFiltersState = {
  names: '',
  flags: '',
  clinics: [],
  addedBy: '',
  subscriptions: '',
};

export const usePatientListFilters = create<patientListFiltersType>(() => defaultFiltersState);

const FilterSelections = (props: {
  allFilterValues: patientListFiltersType,
  setAllFilterValues: (allFilterValues: patientListFiltersType) => void,
  handleClinicChange: (clinicId: number) => void,
}) => {
  const { allFilterValues, setAllFilterValues, handleClinicChange } = props;
  const { t } = useTranslation();
  const flag_filters = getFlagFilters(t);
  const added_by_filters = getAddedByFilter(t);
  const subscription_filters = getSubscriptionFilters(t);
  const { clinician } = useStore();

  const flagFilter = (flag_filters.find(f => f[0] === allFilterValues.flags) || [null, allFilterValues.flags])[1];
  const clinicFilter = clinician.clinics?.filter(clinic => allFilterValues.clinics?.includes(clinic.id));
  const addedByFilter =
    (added_by_filters.find(f => f[0] === allFilterValues.addedBy) || [null, allFilterValues.addedBy])[1];
  const subscriptionFilter =
    (subscription_filters.find(f => f[0] === allFilterValues.subscriptions) || [null, allFilterValues.subscriptions])[
      1
    ];
  return (
    <div className="filterRowContent">
      <Trans>selected</Trans>
      {flagFilter && (
        <span
          className="badge badge-pill badge-light clearFilter mr-2"
          onClick={() => setAllFilterValues({ ...allFilterValues, flags: '' })}
        >
          {flagFilter}
          <i className="fas fa-window-close" />
        </span>
      )}
      {clinicFilter?.map((clinic, idx) => (
        <span
          key={clinic.id}
          className="badge badge-pill badge-light clearFilter mr-2"
          onClick={() => handleClinicChange(clinic.id)}
        >
          {clinic.name}
          <i className="fas fa-window-close" />
        </span>
      ))}
      {addedByFilter && (
        <span
          className="badge badge-pill badge-light clearFilter mr-2"
          onClick={() => setAllFilterValues({ ...allFilterValues, addedBy: '' })}
        >
          {addedByFilter}
          <i className="fas fa-window-close" />
        </span>
      )}
      {subscriptionFilter && (
        <span
          className="badge badge-pill badge-light clearFilter mr-2"
          onClick={() => setAllFilterValues({ ...allFilterValues, subscriptions: '' })}
        >
          {subscriptionFilter}
          <i className="fas fa-window-close" />
        </span>
      )}
    </div>
  );
};

const FilterPanel = (props: {
  allFilterValues: patientListFiltersType,
  setAllFilterValues: (allFilterValues: patientListFiltersType) => void,
  handleClinicChange: (clinicId: number) => void,
}) => {
  const { allFilterValues, setAllFilterValues, handleClinicChange } = props;
  const { clinician, isInternalUser } = useStore();
  const [showAddUser, toggleAddUser] = useState(false);
  const [showInviteCode, setShowInviteCode] = useState(false);
  const [showAdjustSub, toggleAdjustSub] = useState(true);
  const customize = React.useRef(false);
  const subscriptionId = React.useRef(0);
  const patientId = React.useRef(0);
  const trackingStartDate = React.useRef(null);
  const { t } = useTranslation();
  const flag_filters = getFlagFilters(t);
  const added_by_filters = getAddedByFilter(t);
  const subscription_filters = getSubscriptionFilters(t);

  const sortedClinics = clinician.clinics.sort((a, b) => a.name.localeCompare(b.name));
  const clinicianHasBlockAfterDateFlag = !clinician?.clinics?.some(c => c.clinic_type === 'dexcom')
    && clinician?.clinics?.every(c => c.flags?.clinician_prevent_new_patients_after_date);
  const blockAfterDateClinic = clinician?.clinics?.find(c => c.flags?.clinician_prevent_new_patients_after_date);
  const blockAfterDate = (clinicianHasBlockAfterDateFlag && blockAfterDateClinic)
    ? DateTime.fromISO(blockAfterDateClinic.flags?.clinician_prevent_new_patients_after_date)
    : null;
  (window as any).x = DateTime;

  const canAddPatient = !blockAfterDate
    || blockAfterDate.invalidReason
    || blockAfterDate > DateTime.local();
  const canSeeClinicInviteCode = clinician.clinics?.some(c => c.flags?.clinician_can_view_clinic_invite_code);

  return (
    <>
      <InviteCodeModal
        isOpen={showInviteCode}
        toggle={() => {
          setShowInviteCode(!showInviteCode);
        }}
      />
      <AddUser
        show={showAddUser}
        toggle={() => {
          toggleAddUser(!showAddUser);
        }}
        customize={customize}
        subscriptionId={subscriptionId}
        patientId={patientId}
        trackingStartDate={trackingStartDate}
        filteredClinics={props.allFilterValues.clinics || []}
      />
      {customize.current && (
        <SendLoggingTrial
          show={showAdjustSub}
          toggle={() => {
            toggleAdjustSub(!showAdjustSub);
          }}
          customize={customize}
          subscriptionId={subscriptionId}
          trackingStartDate={trackingStartDate}
        />
      )}
      <div className="margin-m filterPanel">
        <MDBFormInline className="md-form">
          <MDBIcon icon="search" />
          <input
            className="form-control form-control-sm ml-3 w-75"
            type="text"
            placeholder={t('Patient Name')}
            value={allFilterValues.names}
            aria-label={t('Search')}
            onChange={evt => setAllFilterValues({ ...allFilterValues, names: evt.target.value })}
          />
        </MDBFormInline>
        <Dropdown className="margin-right-l">
          <Dropdown.Toggle id="flags-toggle" as={FilterDropdown as any}>
            <Trans>Flags</Trans>
            <i className="fas fa-chevron-down" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {flag_filters.filter(item => (
              (isInternalUser && item[0] == 'flagDraftReportAvailable')
              || item[0] != 'flagDraftReportAvailable'
            )).map((f, idx) => {
              return (
                <Dropdown.Item
                  key={idx}
                  className={classnames('filterMenuItem', { active: allFilterValues.flags == f[0] })}
                  onClick={() => setAllFilterValues({ ...allFilterValues, flags: f[0] })}
                >
                  {f[1]}
                  <i className="fas fa-check-circle" />
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="margin-right-l">
          <Dropdown.Toggle id="flags-toggle" as={FilterDropdown as any}>
            <Trans>Clinic</Trans>
            <i className="fas fa-chevron-down" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortedClinics.map((clinic, idx) => {
              return (
                <Dropdown.Item
                  key={idx}
                  className={classnames('filterMenuItem', { active: allFilterValues.clinics?.includes(clinic.id) })}
                  onClick={() => handleClinicChange(clinic.id)}
                >
                  {clinic.name}
                  <i className="fas fa-check-circle" />
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="margin-right-l">
          <Dropdown.Toggle id="flags-toggle" as={FilterDropdown as any}>
            <Trans>Added by</Trans>
            <i className="fas fa-chevron-down" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {added_by_filters.map((user, idx) => {
              return (
                <Dropdown.Item
                  key={idx}
                  className={classnames('filterMenuItem', { active: allFilterValues.addedBy == user[0] })}
                  onClick={() => setAllFilterValues({ ...allFilterValues, addedBy: user[0] })}
                >
                  {user[1]}
                  <i className="fas fa-check-circle" />
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="margin-right-l">
          <Dropdown.Toggle id="flags-toggle" as={FilterDropdown as any}>
            <Trans>Logging trial</Trans>
            <i className="fas fa-chevron-down" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {subscription_filters.map((sub, idx) => {
              return (
                <Dropdown.Item
                  key={idx}
                  className={classnames('filterMenuItem', { active: allFilterValues.subscriptions == sub[0] })}
                  onClick={() => setAllFilterValues({ ...allFilterValues, subscriptions: sub[0] })}
                >
                  {sub[1]}
                  <i className="fas fa-check-circle" />
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <div className="filterPanelLast">
          {canSeeClinicInviteCode && (
            <div
              className="clinicInviteCode"
              onClick={() => setShowInviteCode(true)}
            >
              <Trans>
                Show Invitation Code
              </Trans>
            </div>
          )}

          {canAddPatient && (
            <div
              onClick={() => toggleAddUser(true)}
            >
              <span className="fa-stack">
                <i className="fas fa-circle fa-stack-2x" />
                <i className="fas fa-plus fa-stack-1x" />
              </span>{' '}
              <Trans>new patient</Trans>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const PatientFlags = (props) => {
  const { patient } = props;
  const patientService = PatientService();
  const { addNotification } = useStoreDispatch();
  const { clinician } = useStore();
  const hospital_id = clinician?.hospital_id;
  const { t } = useTranslation();
  const sendRes = useAsyncResult<void>();

  const resendEmail = async () => {
    await hospitalApi.appApiHospitalSendHospitalInvitationEmail({
      patient_id: patient.patient_id,
      hospital_id: hospital_id,
    });
  };

  useEffect(() => {
    if (sendRes.isDone) {
      addNotification([
        t('Invite email sent to {{patientFirstName}} {{patientLastName}}', {
          patientFirstName: patient.first_name,
          patientLastName: patient.last_name,
        }),
        true,
      ]);
      sendRes.clear();
    } else if (sendRes.isError) {
      addNotification([t('Invite email failed to send'), true]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRes.state]);

  const removeUser = (patient_id) => {
    const getData = async () => {
      const res = await patientService.getPatientClinics(patient_id);
      if (res) {
        const ptList = res.map(item => {
          return item.id;
        });
        const clinicianList = clinician.clinics.map(item => {
          return item.id;
        });
        const clinic_ids = ptList.filter(element => clinicianList.includes(element));
        const suc = await patientService.removePatient(patient_id, hospital_id, clinic_ids[0]);
        if (suc) {
          addNotification([t('Removed Patient'), true]);
          window.location.reload();
        }
      }
    };
    getData();
  };

  const hasLoggedRecently = patient.last_meal_date > moment().subtract(1, 'months').format('YYYY-MM-DD');

  return (
    <>
      <i
        title={t('Red - User was active in last month')}
        className={classnames('fas fa-history flagIcon', { active: hasLoggedRecently })}
      />
      <i
        title={t('Resend Invite Email')}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (patient.is_registered) {
            addNotification([t('Patient is already registered'), true]);
            return;
          }
          sendRes.bind(resendEmail());
        }}
        className={classnames('fas fa-envelope flagIcon', { active: !patient.is_registered })}
      />
      <i
        title={t('Delete User')}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (
            window.confirm(t('Are you sure you wish to delete {{patientFirstName}}  {{patientLastName}} ?', {
              patientFirstName: patient.first_name,
              patientLastName: patient.last_name,
            }))
          ) {
            removeUser(patient.patient_id);
          }
        }}
        className={classnames('fa fa-trash flagIcon', { active: true })}
      />
    </>
  );
};

const PatientList = () => {
  const { t } = useTranslation();
  const { clinician } = useStore();
  const patientListFilters = usePatientListFilters();

  const [showEditUser, toggleEditUser] = useState(false);
  const [toggleAddClinician, setToggleAddClinician] = useState(false);
  const [allFilterValues, setAllFilterValues] = useState(defaultFiltersState);
  const filterObj = { nameEmail: allFilterValues.names ? { 'contains': allFilterValues.names } : undefined };

  switch (allFilterValues.flags) {
    case 'flagNotSeen1Month':
      filterObj['last_meal_date'] = {
        'le': moment().subtract(1, 'months').format('YYYY-MM-DD'),
      };
      break;
    case 'hasReportSoon':
      filterObj['next_report_date'] = {
        'ge': DateTime.local().minus({ days: 3 }).toISODate(),
        'le': DateTime.local().plus({ days: 3 }).toISODate(),
      };
      break;
    case 'flagDraftReportAvailable':
      filterObj['has_draft_report'] = true;
      break;
    default:
      break;
  }

  switch (allFilterValues.addedBy) {
    case 'addedByMe':
      filterObj['registered_by_user_id'] = { 'eq': clinician.id };
      break;
    case 'addedByOthers':
      filterObj['registered_by_user_id'] = { 'ne': clinician.id };
      break;
    default:
      break;
  }

  switch (allFilterValues.subscriptions) {
    case 'active':
      filterObj['has_active_subscription'] = true;
      break;
    case 'completed':
      filterObj['has_completed_subscription'] = true;
      break;
    case 'upcoming':
      filterObj['has_upcoming_subscription'] = true;
      break;
    case 'expired':
      filterObj['has_expired_subscription'] = true;
      break;
    default:
      break;
  }

  const handleClinicChange = (clinicId: number) => {
    if (allFilterValues.clinics?.includes(clinicId)) {
      setAllFilterValues({ ...allFilterValues, clinics: allFilterValues.clinics?.filter(id => id !== clinicId) });
    } else {
      setAllFilterValues({ ...allFilterValues, clinics: [...(allFilterValues.clinics || []), clinicId] });
    }
  };
  if (allFilterValues.clinics?.length) {
    filterObj['clinic_ids'] = { 'array_contains_any': allFilterValues.clinics };
  }

  const [q, setQ] = useQueryParams({
    names: StringParam,
    flags: StringParam,
    clinics: withDefault(NumericArrayParam, []),
    addedBy: StringParam,
    subscriptions: StringParam,
    order: StringParam,
  });

  useEffect(() => {
    const filterClinics = (clinics) => clinics?.filter(c => clinician.clinics?.map(c => c.id).includes(c)) || clinics;

    setAllFilterValues({
      names: q.names || patientListFilters.names,
      flags: q.flags || patientListFilters.flags,
      clinics: q.clinics?.length ? filterClinics(q.clinics) : filterClinics(patientListFilters.clinics),
      addedBy: q.addedBy || patientListFilters.addedBy,
      subscriptions: q.subscriptions || patientListFilters.subscriptions,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    usePatientListFilters.setState(allFilterValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFilterValues]);

  return (
    <Container fluid={true}>
      <Row>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={logo} className="headerLogo" alt="logo" />
          <span className="headerTitleLarge">
            <Trans>My patients</Trans>
          </span>
          <i
            style={{
              marginTop: 29,
              marginLeft: 20,
            }}
            className="fas fa-user-md clinicianEditMenuBtn"
            onClick={() => toggleEditUser(true)}
            title={t('Edit My Profile')}
          />
          {clinician.clinics?.some(c => c.clinic_type === 'dexcom') && (
            <i
              style={{
                marginTop: 29,
                marginLeft: 20,
              }}
              className="fas fa-user-plus clinicianEditMenuBtn"
              onClick={() => setToggleAddClinician(true)}
              title={t('Add New Clinician')}
            />
          )}
        </Col>
      </Row>
      {showEditUser && (
        <AddClinician
          mode="edit"
          show={showEditUser}
          toggle={() => toggleEditUser(!showEditUser)}
        />
      )}
      {toggleAddClinician && (
        <AddClinician
          mode="add"
          show={toggleAddClinician}
          toggle={() => setToggleAddClinician(!toggleAddClinician)}
        />
      )}
      <Row>
        <Col>
          <FilterPanel
            allFilterValues={allFilterValues}
            setAllFilterValues={setAllFilterValues}
            handleClinicChange={handleClinicChange}
          />
        </Col>
      </Row>
      <Row
        className={classnames('filterRow', {
          'd-none': !allFilterValues.flags && !allFilterValues.clinics?.length && !allFilterValues.addedBy
            && !allFilterValues.subscriptions,
        })}
      >
        <Col>
          <FilterSelections
            allFilterValues={allFilterValues}
            setAllFilterValues={setAllFilterValues}
            handleClinicChange={handleClinicChange}
          />
        </Col>
      </Row>

      <PatientTable
        filterObj={filterObj}
        allFilterValues={allFilterValues}
        q={q}
        setQ={setQ}
        extraColumns={[
          {
            header: t('Flags'),
            render: (patient) => <PatientFlags patient={patient} />,
            onClick: null,
          },
        ]}
      />
    </Container>
  );
};

export default withRouter(PatientList);
