/* eslint-disable i18next/no-literal-string */
import { Box, FormControl, FormLabel, Grid, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useAsyncResult } from 'react-use-async-result';
import { mealApi, openAIApi, patientApi, recipeApi } from '../api';
import { useTranslation } from '../i18n';
import { Input } from './input';
import { RecipeSearchList } from './RecipeSearchList';
import './recipeSearchModal.scss';
import {
  OpenAIChatCompletionResponse,
  PatientDietRestrictionResponse,
  PatientMotivationResponse,
  RecipeResponse,
} from '../api/generated';
import { EDAMAM_DIET_LABELS, EDAMAM_FILTERS, EDAMAM_HEALTH_LABELS, EDAMAM_MEAL_TYPE_LABELS } from '../constants';
import { usePatientRecipeList } from '../pages/patient-recipes';
import { MuiCustomSelect, MuiCustomTextField } from './muiCustomInput';
import { RecipeSearchGrid } from './RecipeSearchGrid';

const defaultPrompt = `
  I care about: [motivations]
  My dietary restrictions are: [restrictions]
  Recently I've eaten: [recent_foods]
  Try to include the following ingredients in your suggestion: [ingredients]
  And please keep in mind: [context]

  Please suggest [suggestion] that focuses on [focuses], based on foods similar to those I've eaten recently.
`.trim().split('\n').map((line) => line.trim()).join('\n');

const recipeSearchPrompt = `
  I care about: [motivations]
  My dietary restrictions are: [restrictions]
  Recently I've eaten: [recent_foods]
  Try to include the following ingredients in your suggestion: [ingredients]
  And please keep in mind: [context]

  Please suggest [suggestion] that focuses on [focuses], based on foods similar to those I've eaten recently.
  Give your answer as a single numbered list of recipe names and nothing else.
`.trim().split('\n').map((line) => line.trim()).join('\n');

const portfolioDietExampleText = `
  Some examples of foods that follow the portfolio diet are: walnuts, nuts, nut butter, legumes, chickpeas,
  lentils, peas, tempeh, tofu, soybeans, apple, eggplant, okra, oatmeal, berries, psyllium, oat meal, oat bran and barley.

  Reference these foods when suggesting recipes.
`.trim().split('\n').map((line) => line.trim()).join('\n');

const listToggle = (list: string[], item: string) => {
  if (list.includes(item)) {
    return list.filter((i) => i !== item);
  }
  return [...list, item];
};

function joinPatientMotivationsRestrictions(vals: Array<PatientDietRestrictionResponse | PatientMotivationResponse>) {
  if (!vals) {
    return '';
  }

  return vals
    .filter((val) => val.name != 'other' || val.comment)
    .map((val) => {
      if (val.name == 'other') {
        return val.comment;
      }
      return val.name;
    })
    .join(', ');
}

export const PatientAutopilot = (props: {
  patientId: number,
}) => {
  const { patientId } = props;
  const { t } = useTranslation();

  const patientQuery = useQuery(['patient-autopilot-meals', patientId], async () => {
    const patientRes = await patientApi.appApiPatientGetPatient({
      patient_id: patientId,
    });

    const mealsRes = await mealApi.appApiMealGetMealsTable({
      patient_id: patientId,
    });

    return {
      patient: patientRes.data,
      meals: mealsRes.data,
    };
  });

  const chatRes = useAsyncResult<OpenAIChatCompletionResponse>();
  const handleSubmit = () => {
    if (isFoodSubstitution) {
      chatRes.bind(
        openAIApi.appApiOpenaiPostFoodSubstitutionsChatCompletion({
          OpenAIChatCompletionRequest: {
            prompt: buildPrompt(),
            temperature: 0.5,
          },
        }).then(res => res.data),
      );
    } else if (isMealPlan) {
      chatRes.bind(
        openAIApi.appApiOpenaiPostMealPlanChatCompletion({
          OpenAIChatCompletionRequest: {
            prompt: buildPrompt(),
            temperature: 0.5,
          },
        }).then(res => res.data),
      );
    } else {
      chatRes.bind(
        openAIApi.appApiOpenaiPostChatCompletion({
          OpenAIChatCompletionRequest: {
            prompt: buildPrompt(),
          },
        }).then(res => res.data),
      );
    }
  };

  const validateNutrientInput = (value: string) => {
    const validFormats = [/^\d+\-\d+$/, /^\d+\+$/, /^\d+$/, /^$/];
    const isValid = validFormats.some((format) => format.test(value));
    if (!isValid) {
      console.log('invalid format: ', value);
    }
    return isValid;
  };

  const [showCustomRecipeModal, _setShowCustomRecipeModal] = React.useState(false);
  const handleCustomRecipeSearch = () => {
    if (customRecipeForm.recipeLabel === '') {
      return;
    }
    const nutrients = Object.fromEntries(
      Object.entries(customRecipeForm).filter(([key, _]) => key !== 'recipeLabel'),
    );
    for (const value of Object.values(nutrients)) {
      if (!validateNutrientInput(value)) {
        return;
      }
    }
    _setShowCustomRecipeModal(!showCustomRecipeModal);
  };

  const formatChatResult = () => {
    if (!chatRes.isDone) {
      return;
    }
    const reg = /^\d+\s*[.)]?\s*/g;
    return chatRes.result.text.split('\n').map(rec => rec.replace(reg, ''));
  };

  const suggestions = [
    {
      label: t('some healthy recipes'),
      text: 'healthy recipes',
      prompt: '15 simple, healthy and varied recipes',
    },
    {
      label: t('a healthy meal plan'),
      text: 'a healthy meal plan',
      prompt: 'a 3 day healthy meal plan',
    },
    {
      label: t('healthy substitutions'),
      text: 'healthy substitutions',
      prompt: 'healthy substitutions',
    },
  ];

  const dietaryPatterns = [
    t('Mediterranean diet'),
    t('balanced diet'),
    t('DASH diet'),
    t('MIND diet'),
    t('Portfolio diet'),
  ];

  const dietaryRestrictions = [
    t('kosher'),
    t('keto'),
    t('paleo'),
    t('pescatarian'),
    t('vegan'),
    t('vegetarian'),
    t('no dairy'),
    t('no egg'),
    t('no fish'),
    t('gluten free'),
    t('peanut free'),
    t('no pork'),
    t('no red meat'),
    t('no shellfish'),
    t('no soy'),
    t('no sulfites'),
    t('no tree nuts'),
    t('no wheat'),
    t('no added oil'),
  ];

  const mealTypes = [
    t('breakfast'),
    t('lunch'),
    t('dinner'),
    t('snack'),
  ];

  const specificGoals = [
    t('increasing fibre'),
    t('increasing protein'),
    t('increasing calcium'),
    t('increasing iron'),
    t('increasing potassium'),
    t('increasing plant-based foods'),
    t('increasing whole foods'),
    t('low carb'),
    t('low fat'),
    t('low glycemic index'),
    t('reducing sodium'),
    t('reducing potassium'),
    t('reducing sugar'),
  ];

  const otherFactors = [
    t('diabetes management'),
    t('cholesterol management'),
    t('blood pressure management'),
    t('immuno-supportive'),
    t('kidney friendly'),
    t('low cost'),
    t('quick and easy to make'),
  ];

  const [form, _setForm] = React.useState({
    promptTemplate: defaultPrompt,
    suggestion: 0,
    focuses: [],
    context: '',
    ingredients: '',
  });
  const [customRecipeForm, _setCustomRecipeForm] = React.useState({
    recipeLabel: '',
    calories: '',
    fat: '',
    netCarbs: '',
    protein: '',
  });
  const [isRecipeSearch, _setRecipeSearch] = React.useState(false);
  const [isFoodSubstitution, _setFoodSubstitution] = React.useState(false);
  const [isMealPlan, _setMealPlan] = React.useState(false);
  const [isCustomRecipeSearch, _setCustomRecipeSearch] = React.useState(false);

  const setSuggestionType = (suggestionIndex: string) => {
    _setRecipeSearch(suggestionIndex == '0');
    _setMealPlan(suggestionIndex == '1');
    _setFoodSubstitution(suggestionIndex == '2');
  };

  const setForm = (vals: Partial<typeof form>) => {
    _setForm({
      ...form,
      ...vals,
    });
  };

  const setCustomRecipeForm = (vals: Partial<typeof customRecipeForm>) => {
    _setCustomRecipeForm({
      ...customRecipeForm,
      ...vals,
    });
  };

  const [showDebug, setShowDebug] = React.useState(false);
  useEffect(() => {
    if (form.context == '(debug)') {
      setShowDebug(true);
    }
  }, [form.context]);

  const copyRes = useAsyncResult();
  const handleCopy = () => {
    if (!chatRes.isDone) {
      return;
    }
    copyRes.bind(navigator.clipboard.writeText(chatRes.result.text));
  };

  const [recipesToAdd, setRecipesToAdd] = useState<RecipeResponse[]>([]);
  const recipeListQuery = usePatientRecipeList();
  const queryClient = useQueryClient();

  const handleHideModal = async () => {
    if (!recipeListQuery.query.isSuccess) {
      return;
    }
    for (const recipe of recipesToAdd) {
      await recipeApi.appApiRecipePostPatientRecipeListItem({
        patient_id: patientId,
        recipe_list_id_or_type: recipeListQuery.list.id,
        CreatePatientRecipeListItemRequest: {
          recipe_id: recipe.id,
        },
      });
    }
    setRecipesToAdd([]);
    copyRes.clear();

    const timer = setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['patient-recipe-list', patientId] });
    }, 100);

    return () => clearTimeout(timer);
  };

  const recentFoodsToShow = React.useMemo(() => {
    const recentFoods = new Set();
    patientQuery.data?.meals?.rows.forEach((meal) => {
      meal.meal_items.forEach((mealItem) => {
        if (!mealItem.food_name || mealItem.food_name.indexOf('awaiting verification') >= 0) {
          return;
        }
        recentFoods.add(mealItem.food_name);
      });
    });

    return Array.from(recentFoods)
      .sort(() => Math.random() - 0.5)
      .slice(0, 50)
      .sort();
  }, [patientQuery.data?.meals]);

  const unhealthyRecentFoodsToShow = React.useMemo(() => {
    const recentFoods = new Set();
    patientQuery.data?.meals?.rows.forEach((meal) => {
      meal.meal_items.forEach((mealItem) => {
        if (!mealItem.food_name || mealItem.food_name.indexOf('awaiting verification') >= 0) {
          return;
        }
        if (
          mealItem.cc_columns.cc_ultraprocessed || mealItem.cc_columns.cc_sugary_beverage
          || mealItem.cc_columns.cc_unhealthy_fat || mealItem.cc_columns.cc_redmeat
          || mealItem.cc_columns.cc_simplecarbs || mealItem.cc_columns.cc_fried
        ) {
          recentFoods.add(mealItem.food_name);
        }
      });
    });

    return Array.from(recentFoods)
      .sort(() => Math.random() - 0.5)
      .slice(0, 50)
      .sort();
  }, [patientQuery.data?.meals]);

  const buildCustomRecipeSearchFilters = () => {
    const filters = form.focuses;
    const params = {
      'diet': filters.filter((focus) => EDAMAM_DIET_LABELS.includes(EDAMAM_FILTERS[focus]))
        .map((focus) => EDAMAM_FILTERS[focus]),
      'health': filters.filter((focus) => EDAMAM_HEALTH_LABELS.includes(EDAMAM_FILTERS[focus]))
        .map((focus) => EDAMAM_FILTERS[focus]),
      'mealType': filters.filter((focus) => EDAMAM_MEAL_TYPE_LABELS.includes(EDAMAM_FILTERS[focus]))
        .map((focus) => EDAMAM_FILTERS[focus]),
      'nutrients[ENERC_KCAL]': [customRecipeForm.calories || '0+'],
      'nutrients[CHOCDF.net]': [customRecipeForm.netCarbs || '0+'],
      'nutrients[FAT]': [customRecipeForm.fat || '0+'],
      'nutrients[PROCNT]': [customRecipeForm.protein || '0+'],
    };
    return params;
  };

  const buildRecipeSearchFilters = () => {
    const filters = form.focuses;
    const params = {
      'diet': filters.filter((focus) => EDAMAM_DIET_LABELS.includes(EDAMAM_FILTERS[focus]))
        .map((focus) => EDAMAM_FILTERS[focus]),
      'health': filters.filter((focus) => EDAMAM_HEALTH_LABELS.includes(EDAMAM_FILTERS[focus]))
        .map((focus) => EDAMAM_FILTERS[focus]),
      'mealType': filters.filter((focus) => EDAMAM_MEAL_TYPE_LABELS.includes(EDAMAM_FILTERS[focus]))
        .map((focus) => EDAMAM_FILTERS[focus]),
    };

    return params;
  };

  const buildPrompt = () => {
    const { patient } = patientQuery.data ?? {};

    const formValues = {
      ...form,
      focuses: form.focuses.join(', '),
      recent_foods: isFoodSubstitution ? unhealthyRecentFoodsToShow.join(', ') : recentFoodsToShow.join(', '),
      motivations: joinPatientMotivationsRestrictions(patient?.motivations),
      restrictions: joinPatientMotivationsRestrictions(patient?.diet_restrictions),
      context: form.context,
      ingredients: form.ingredients,
    };

    if (isRecipeSearch) {
      if (form.focuses.includes('Portfolio diet')) {
        form.promptTemplate = recipeSearchPrompt.concat('\n', portfolioDietExampleText);
      } else {
        form.promptTemplate = recipeSearchPrompt;
      }
    } else {
      form.promptTemplate = defaultPrompt;
    }

    return form.promptTemplate.replace(/\[(\w+)\]/g, (match, key) => {
      const val = (key === 'suggestion') ? suggestions[formValues[key]].prompt : formValues[key];
      if (val === undefined) {
        return `[${key}]`;
      }
      return val || 'nothing specific';
    });
  };

  return (
    <div>
      <Form.Label className="mb-0">
        <h5>
          <Trans>Suggest…</Trans>
        </h5>
      </Form.Label>
      <Form.Group>
        <Form.Group style={{ fontSize: '14px' }}>
          {suggestions.map((suggestion, index) => (
            <Form.Check
              key={suggestion.label}
              inline
              id={`autopilot-suggest-${suggestion.label}`}
              name="autopilot-suggest-type"
              type="radio"
              value={index}
              label={suggestion.label}
              onChange={(e) => {
                setForm({ suggestion: e.target.value });
                setSuggestionType(e.target.value);
                _setCustomRecipeSearch(false);
              }}
            />
          ))}
          <Form.Check
            inline
            id="auto-pilot-suggest-custom-recipe-search"
            name="autopilot-suggest-type"
            type="radio"
            checked={isCustomRecipeSearch}
            label={t('some recipes by custom search')}
            onChange={() => {
              _setCustomRecipeSearch(true);
            }}
          />
        </Form.Group>
        {isCustomRecipeSearch && (
          <Grid container direction="column" spacing={1} style={{ fontWeight: 'normal', paddingTop: '16px' }}>
            <Grid item xs={2}>
              <Grid direction="column" container>
                <Typography style={{ textTransform: 'none' }}>
                  Recipe Name*
                </Typography>
                <MuiCustomTextField
                  error={!!(customRecipeForm.recipeLabel === '')}
                  helperText={customRecipeForm.recipeLabel === '' ? 'Recipe name is required' : ''}
                  onChange={(e) => {
                    e && setCustomRecipeForm({ recipeLabel: e.target.value });
                  }}
                  variant="outlined"
                  style={{ minWidth: '200px', maxHeight: '56px', marginBottom: '16px' }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <RecipesNutrientInput
                nutrient="calories"
                label="Calories"
                unit="kcal"
                setCustomRecipeForm={setCustomRecipeForm}
                customRecipeForm={customRecipeForm}
              />
            </Grid>
            <Grid item xs={2}>
              <RecipesNutrientInput
                nutrient="netCarbs"
                label="Net Carbs"
                unit="g"
                setCustomRecipeForm={setCustomRecipeForm}
                customRecipeForm={customRecipeForm}
              />
            </Grid>
            <Grid item xs={2}>
              <RecipesNutrientInput
                nutrient="fat"
                label="Fat"
                unit="g"
                setCustomRecipeForm={setCustomRecipeForm}
                customRecipeForm={customRecipeForm}
              />
            </Grid>
            <Grid item xs={2}>
              <RecipesNutrientInput
                nutrient="protein"
                label="Protein"
                unit="g"
                setCustomRecipeForm={setCustomRecipeForm}
                customRecipeForm={customRecipeForm}
              />
            </Grid>
          </Grid>
        )}
      </Form.Group>

      <Form.Label className="mb-0 mt-2">
        <h5>
          <Trans>That focuses on…</Trans>
        </h5>
      </Form.Label>
      <Form.Group>
        <Form.Group>
          <Typography variant="subtitle1">
            <Box className="autopilot-checkbox-group-label">
              <Trans>Dietary Pattern</Trans>
            </Box>
          </Typography>
          <Box className="autopilot-checkbox-group">
            {dietaryPatterns.map((focus, idx) => (
              <Form.Check
                className="autopilot-checkbox"
                key={focus}
                id={`autopilot-focus-${focus}`}
                value={focus}
                label={focus}
                onChange={(e) => setForm({ focuses: listToggle(form.focuses, e.target.value) })}
              />
            ))}
          </Box>
        </Form.Group>
        <Form.Group>
          <Typography variant="subtitle1">
            <Box className="autopilot-checkbox-group-label">
              <Trans>Dietary Restrictions</Trans>
            </Box>
          </Typography>
          <Box className="autopilot-checkbox-group">
            {dietaryRestrictions.map((focus, idx) => (
              <Form.Check
                className="autopilot-checkbox"
                key={focus}
                id={`autopilot-focus-${focus}`}
                value={focus}
                label={focus}
                onChange={(e) => setForm({ focuses: listToggle(form.focuses, e.target.value) })}
              />
            ))}
          </Box>
        </Form.Group>
        <Form.Group>
          <Typography variant="subtitle1">
            <Box className="autopilot-checkbox-group-label">
              <Trans>Meal Type</Trans>
            </Box>
          </Typography>
          <Box className="autopilot-checkbox-group">
            {mealTypes.map((focus, idx) => (
              <Form.Check
                className="autopilot-checkbox"
                key={focus}
                id={`autopilot-focus-${focus}`}
                value={focus}
                label={focus}
                onChange={(e) => setForm({ focuses: listToggle(form.focuses, e.target.value) })}
              />
            ))}
          </Box>
        </Form.Group>
        <Form.Group>
          <Typography variant="subtitle1">
            <Box className="autopilot-checkbox-group-label">
              <Trans>Specific Goals</Trans>
            </Box>
          </Typography>
          <Box className="autopilot-checkbox-group">
            {specificGoals.map((focus, idx) => (
              <Form.Check
                className="autopilot-checkbox"
                key={focus}
                id={`autopilot-focus-${focus}`}
                value={focus}
                label={focus}
                onChange={(e) => setForm({ focuses: listToggle(form.focuses, e.target.value) })}
              />
            ))}
          </Box>
        </Form.Group>
        <Form.Group>
          <Typography variant="subtitle1">
            <Box className="autopilot-checkbox-group-label">
              <Trans>Other Factors</Trans>
            </Box>
          </Typography>
          <Box className="autopilot-checkbox-group">
            {otherFactors.map((focus, idx) => (
              <Form.Check
                className="autopilot-checkbox"
                key={focus}
                id={`autopilot-focus-${focus}`}
                value={focus}
                label={focus}
                onChange={(e) => setForm({ focuses: listToggle(form.focuses, e.target.value) })}
              />
            ))}
          </Box>
        </Form.Group>
      </Form.Group>

      <Input
        label={t('Additional ingredients to incorporate')}
        onChange={(e) => setForm({ ingredients: e.target.value })}
      />

      <Input
        label={t('Additional context (dietary preferences, meal types, etc)')}
        onChange={(e) => setForm({ context: e.target.value })}
      />

      {showDebug && (
        <>
          <FormControl>
            <FormLabel>Prompt Template</FormLabel>
            <TextField
              multiline
              fullWidth
              rows={8}
              defaultValue={defaultPrompt}
              onChange={(e) => setForm({ promptTemplate: e.target.value })}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Prompt</FormLabel>
            <code style={{ whiteSpace: 'pre-wrap' }}>{buildPrompt()}</code>
          </FormControl>
          <div style={{ height: 20 }} />
        </>
      )}

      {!isCustomRecipeSearch && (
        <Button onClick={handleSubmit} block disabled={chatRes.isPending}>
          {!chatRes.isPending && <Trans>Create {{ type: suggestions[form.suggestion].text }}</Trans>}
          {chatRes.isPending && <Trans>Creating {{ type: suggestions[form.suggestion].text }}…</Trans>}
        </Button>
      )}

      {isCustomRecipeSearch && (
        <Button onClick={handleCustomRecipeSearch} block disabled={chatRes.isPending}>
          <Trans>Search For {{ type: 'custom recipe' }}</Trans>
        </Button>
      )}

      {chatRes.isError && <code>Error: {'' + chatRes.error}</code>}

      {chatRes.isDone && (
        <MDBModal
          centered
          isOpen
          toggle={() => {
            chatRes.clear();
            handleHideModal();
          }}
          className="recipe-search-modal"
        >
          <MDBModalHeader
            toggle={() => {
              chatRes.clear();
              handleHideModal();
            }}
            style={{ textTransform: 'capitalize' }}
          >
            {suggestions[form.suggestion].text} for {patientQuery.data?.patient?.first_name}
          </MDBModalHeader>

          <MDBModalBody>
            {isRecipeSearch && (
              <div
                style={{
                  maxHeight: '600px',
                  whiteSpace: 'nowrap',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  marginRight: '-1rem',
                }}
              >
                <Grid container direction="column" spacing={2}>
                  {formatChatResult().map((recipe, index) => (
                    <RecipeSearchList
                      key={index}
                      setRecipesToAdd={setRecipesToAdd}
                      patientId={patientId}
                      focuses={buildRecipeSearchFilters()}
                      gptRecipe={recipe}
                    />
                  ))}
                </Grid>
              </div>
            )}
            {!isRecipeSearch && (
              <div>
                <div style={{ whiteSpace: 'pre-wrap', maxHeight: 500, overflowY: 'auto' }}>
                  {chatRes.result.text}
                </div>

                <div style={{ height: 20 }} />

                <Button block disabled={copyRes.isPending} onClick={handleCopy}>
                  {!copyRes.isDone && <Trans>Copy to clipboard</Trans>}
                  {copyRes.isDone && <Trans>Copied!</Trans>}
                </Button>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
      )}

      {showCustomRecipeModal && (
        <MDBModal
          centered
          isOpen
          toggle={() => {
            _setShowCustomRecipeModal(false);
            handleHideModal();
          }}
          className="custom-recipe-search-modal"
        >
          <MDBModalHeader
            toggle={() => {
              _setShowCustomRecipeModal(false);
              handleHideModal();
            }}
            style={{ textTransform: 'capitalize' }}
          >
            Custom Recipe Search
          </MDBModalHeader>

          <MDBModalBody>
            <div
              style={{
                maxHeight: '600px',
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                overflowY: 'auto',
                marginRight: '-1rem',
              }}
            >
              <Grid container direction="column" spacing={2}>
                <RecipeSearchGrid
                  patientId={patientId}
                  setRecipesToAdd={setRecipesToAdd}
                  focuses={buildCustomRecipeSearchFilters()}
                  gptRecipe={customRecipeForm.recipeLabel}
                />
              </Grid>
            </div>
          </MDBModalBody>
        </MDBModal>
      )}
    </div>
  );
};

export const RecipesNutrientInput = (props: {
  nutrient: string,
  label: string,
  unit: string,
  setCustomRecipeForm: (e) => void,
  customRecipeForm: {
    recipeLabel: string,
    calories: string,
    fat: string,
    netCarbs: string,
    protein: string,
  },
}) => {
  const { nutrient, label, unit, setCustomRecipeForm, customRecipeForm } = props;

  const [value, setValue] = useState({ inputValue: '', secondInputValue: '' });
  // eslint-disable-next-line i18next/no-literal-string
  const dropdownValues = ['less than', 'more than', 'range'];
  const [dropdownValue, setDropdownValue] = useState(dropdownValues[0]);
  const startAdornment = dropdownValue === 'less than' ? '<' : dropdownValue === 'more than' ? '>' : '';

  const parseInput = (input: string) => {
    if (input === '+' || input === '-') {
      return '';
    } else if (input.match(/^\d+\-$/)) {
      return input.replace('-', '+');
    } else if (input.match(/^\-\d+$/)) {
      return input.replace('-', '');
    }
    return input;
  };
  useEffect(() => {
    if (dropdownValue === 'less than') {
      setCustomRecipeForm(
        { ...customRecipeForm, [nutrient]: parseInput(`${value.inputValue}`) },
      );
    } else if (dropdownValue === 'more than') {
      setCustomRecipeForm(
        { ...customRecipeForm, [nutrient]: parseInput(`${value.inputValue}+`) },
      );
    } else {
      setCustomRecipeForm(
        { ...customRecipeForm, [nutrient]: parseInput(`${value.inputValue}-${value.secondInputValue}`) },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, dropdownValue]);

  const secondInputValueError = !!isNaN(+value.secondInputValue)
    || !!(value.secondInputValue && value.secondInputValue < value.inputValue);
  const secondInputValueHelperText = value.secondInputValue && value.secondInputValue < value.inputValue
    ? 'Max must be greater than min'
    : isNaN(+value.secondInputValue)
    ? 'Value must be a number'
    : '';
  return (
    <Grid container>
      <Typography style={{ textTransform: 'none' }}>
        {label}
      </Typography>
      <div
        style={{ display: 'flex', gap: '10px' }}
      >
        <MuiCustomSelect
          value={dropdownValue}
          onChange={(e) => setDropdownValue(e.target.value as string)}
          variant="outlined"
          style={{ minWidth: '200px', maxHeight: '56px' }}
          fullWidth
        >
          {dropdownValues.map((value, idx) => (
            <MenuItem key={idx} value={value}>
              {value}
            </MenuItem>
          ))}
        </MuiCustomSelect>

        {dropdownValue !== 'range'
          ? (
            <MuiCustomTextField
              error={isNaN(+value.inputValue)}
              helperText={isNaN(+value.inputValue) ? 'Value must be a number' : ''}
              value={value.inputValue}
              onChange={e => e && setValue({ ...value, inputValue: e.target.value })}
              InputProps={{
                startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
                endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
              }}
              variant="outlined"
              style={{ minWidth: '200px', maxHeight: '56px' }}
              fullWidth
            />
          )
          : (
            <div style={{ display: 'flex' }}>
              <MuiCustomTextField
                error={isNaN(+value.inputValue)}
                helperText={isNaN(+value.inputValue) ? 'Value must be a number' : ''}
                value={value.inputValue}
                onChange={e => e && setValue({ ...value, inputValue: e.target.value })}
                label="Min"
                InputProps={{
                  endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                }}
                variant="outlined"
                style={{ minWidth: '200px', maxHeight: '56px' }}
                fullWidth
              />
              <MuiCustomTextField
                error={secondInputValueError}
                helperText={secondInputValueHelperText}
                value={value.secondInputValue}
                onChange={e => e && setValue({ ...value, secondInputValue: e.target.value })}
                label="Max"
                InputProps={{
                  endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                }}
                variant="outlined"
                style={{ minWidth: '200px', maxHeight: '56px' }}
                fullWidth
              />
            </div>
          )}
      </div>
    </Grid>
  );
};
